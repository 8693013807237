import * as firebase from "firebase";
import { FirebaseConfig } from "../config/keys";
firebase.initializeApp({
  apiKey: "AIzaSyAgT_EZoaaS9voiPi7CAtaBtafa6fimR6Q",
  authDomain: "click-reactor-aa2dc.firebaseapp.com",
  databaseURL: "https://click-reactor-aa2dc.firebaseio.com",
  projectId: "click-reactor-aa2dc",
  storageBucket: "click-reactor-aa2dc.appspot.com",
  messagingSenderId: "900382132573",
  appId: "1:900382132573:web:9b94f43db429ca09",
});
firebase.performance();
export const databaseRef = firebase.database().ref();
