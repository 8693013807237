export default [
    {
        id: 'sticks',
        name: 'Rub Sticks',
        basePrice: 10,
        baseCPS: 0.2,
        css: 'linear-gradient(to top, #c79081 0%, #dfa579 100%)',
        icon: 'M13 24h-2v-5.047c-5.047-.478-9-4.536-9-9.453 0-5.238 4.486-9.5 10-9.5s10 4.262 10 9.5c0 4.918-3.954 8.975-9 9.453v5.047zm0-7.058c3.935-.463 6.989-3.624 6.989-7.442 0-4.136-3.584-7.5-7.989-7.5-4.405 0-7.989 3.364-7.989 7.5 0 3.818 3.054 6.978 6.989 7.441l-.001-2.355-5.216-3.804.589-.808 4.627 3.374v-2.96l-3.407-2.508.593-.806 2.814 2.072v-2.564l-1.621-1.161.582-.813 2.039 1.439 2.048-1.439.582.813-1.63 1.167v2.564l2.823-2.078.593.806-3.416 2.514v2.96l4.636-3.38.589.808-5.225 3.81.001 2.35z'
    },
    {
        id: 'matches',
        name: 'Matches',
        basePrice: 20,
        baseCPS: 0.4,
        css: 'linear-gradient(135deg, #fa709a 0%, #fee140 100%)',
        icon: 'M7.467 0c1.102.018 5.555 2.549 6.386 8.558.905-.889 1.409-3.664 1.147-4.843 3.952 2.969 6 6.781 6 11.034 0 5.094-3.43 9.251-8.963 9.251-5.728 0-9.037-3.753-9.037-8.276 0-6.26 5.052-7.62 4.467-15.724zm3.262 19.743c-.749.848-.368 1.945.763 2.045 1.035.093 1.759-.812 2.032-1.792.273-.978.09-2.02-.369-2.893-.998 1.515-1.52 1.64-2.426 2.64zm4.42 1.608c2.49-1.146 3.852-3.683 3.852-6.58 0-2.358-.94-4.977-2.5-7.04-.743 2.867-2.924 3.978-4.501 4.269.05-3.219-.318-6.153-2.602-8.438-.296 4.732-4.321 7.63-4.398 12.114-.029 1.511.514 3.203 1.73 4.415.491.489 1.054.871 1.664 1.16-.121-.608-.062-1.254.195-1.848.911-2.106 3.333-2.321 4.202-5.754.952.749 3.275 3.503 2.778 6.358-.082.469-.224.923-.42 1.344z'
    },
    {
        id: 'firecracker',
        name: 'Firecracker',
        basePrice: 30,
        baseCPS: 0.6,
        css: 'linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%)',
        icon: 'M7.285 1.942h.005-.005zm-4.133-1.942l-.765 2.412-2.359.917 2.057 1.474.144 2.527 2.038-1.501 2.447.643-.798-2.401 1.369-2.129-2.531.017-1.602-1.959zm.395 2.062l.736.901 1.162-.009-.629.978.366 1.104-1.123-.296-.937.69-.066-1.161-.945-.677 1.084-.422.352-1.108zm3.027 5.938l-3.619.469c.418 4.888-1.546 10.626-2.955 14.531 2.036-2.653 6.729-7.553 6.574-15m-1.016 1.14c-.164 3.308-1.348 6.078-2.705 8.308.69-2.563 1.225-5.419 1.152-8.107l1.553-.201zm15.269 2.436l-1.572 1.983-2.532.02 1.4 2.109-.763 2.413 2.439-.68 2.058 1.471.108-2.528 2.035-1.504-2.371-.883-.802-2.401zm-.363 2.068l.368 1.103 1.09.406-.936.691-.05 1.161-.945-.676-1.121.313.351-1.109-.643-.968 1.163-.009.723-.912zm-4.942.693c-6.271 1.285-12.065 6.879-13.919 9.663 5.435-3.695 10.819-5.736 14.592-6.001l-.673-3.662zm-.792 1.221l.287 1.563c-2.335.334-5.027 1.209-7.81 2.517 2.125-1.697 4.738-3.298 7.523-4.08m-5.805-.305c.276-.01.492-.241.483-.517-.101-2.966.579-3.95 2.91-4.211 2.769-.312 2.914-2.021 3.02-3.268.101-1.2.163-1.924 1.813-2.046 1.542-.113 2.398-.423 2.953-1.07.606-.706.791-1.771.641-3.678-.022-.275-.284-.475-.537-.459-.276.022-.482.263-.459.538.125 1.591.004 2.472-.403 2.947-.359.418-1.038.635-2.268.725-2.501.184-2.637 1.788-2.737 2.959-.105 1.241-.181 2.138-2.134 2.357-3.288.37-3.896 2.362-3.798 5.24.008.27.23.483.499.483h.017zm2.075-13.253c-1.104 0-2 .896-2 2s.896 2 2 2c1.103 0 2-.896 2-2s-.897-2-2-2m0 1c.551 0 1 .448 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.552.449-1 1-1m10.011 2.978c-1.11 0-2.011.901-2.011 2.011s.901 2.011 2.011 2.011 2.011-.901 2.011-2.011-.901-2.011-2.011-2.011m0 1c.557 0 1.011.453 1.011 1.011 0 .557-.454 1.011-1.011 1.011-.558 0-1.011-.454-1.011-1.011 0-.558.453-1.011 1.011-1.011'
    },
    {
        id: 'M80',
        name: 'M80',
        basePrice: 40,
        baseCPS: 0.8,
        css: 'linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898'
    },
    {
        id: 'dynomite',
        name: 'Dynomite',
        basePrice: 100,
        baseCPS: 1.5,
        css: 'linear-gradient(-60deg, #16a085 0%, #f4d03f 100%)',
    },
    {
        id: 'c4',
        name: 'C4',
        basePrice: 500,
        baseCPS: 3.0,
        css: 'linear-gradient(to right, #f83600 0%, #f9d423 100%)',
    },
]
